import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import Featured from './Featured';
import ForYou from './ForYou';
import TheLatest from './TheLatest';
import styles from './HeroZone.module.scss';
import { VIEW_PORTS } from '../../constants';
import useWindowResize from '../../hooks/useWindowResize';

const HeroZone = ({ heroContent }) => {
  const { windowWidth } = useWindowResize();
  const { featuredArticle, forYouArticles, latestArticles } = heroContent;
  const isMobile = windowWidth < VIEW_PORTS.MOBILE;

  return (
    <section className={styles['hero-zone']}>
      {!isEmpty(featuredArticle) && (
        <Featured article={featuredArticle} isMobile={isMobile} />
      )}

      {!isEmpty(forYouArticles) && (
        <ForYou
          head="For You"
          articles={forYouArticles.content}
          status={[
            {
              ...forYouArticles.status,
              ...{ subscriberSwapKey: 'forYouArticles' },
            },
          ]}
          isMobile={isMobile}
        />
      )}

      {!isEmpty(latestArticles) && (
        <TheLatest
          head="The Latest"
          articles={latestArticles.content}
          status={latestArticles.status}
          isMobile={isMobile}
        />
      )}
    </section>
  );
};

HeroZone.propTypes = {
  heroContent: PropTypes.shape({
    featuredArticle: PropTypes.shape({
      'response-status': PropTypes.shape({
        'status-code': PropTypes.number,
        message: PropTypes.string,
      }).isRequired,
      type: PropTypes.string.isRequired,
      uri: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      dek: PropTypes.string,
      authors: PropTypes.arrayOf(PropTypes.string).isRequired,
      image: PropTypes.shape({
        sizes: PropTypes.string,
        srcset: PropTypes.string,
        uri: PropTypes.string,
      }).isRequired,
      topic: PropTypes.shape({
        name: PropTypes.string,
        uri: PropTypes.string,
      }).isRequired,
    }),
    forYouArticles: PropTypes.shape({
      content: PropTypes.arrayOf(
        PropTypes.shape({
          authors: PropTypes.arrayOf(PropTypes.string),
          image: PropTypes.shape({
            sizes: PropTypes.string,
            srcset: PropTypes.string,
            uri: PropTypes.string,
          }),
          topic: PropTypes.shape({
            name: PropTypes.string.isRequired,
            uri: PropTypes.string.isRequired,
          }),
          dek: PropTypes.string,
          title: PropTypes.string,
          type: PropTypes.string,
          uri: PropTypes.string,
        })
      ),
      status: PropTypes.shape({
        'status-code': PropTypes.number,
        message: PropTypes.string,
      }).isRequired,
    }),
    latestArticles: PropTypes.shape({
      content: PropTypes.arrayOf(
        PropTypes.shape({
          type: PropTypes.string.isRequired,
          uri: PropTypes.string.isRequired,
          title: PropTypes.string.isRequired,
          dek: PropTypes.string,
          authors: PropTypes.arrayOf(PropTypes.string),
          image: PropTypes.shape({
            uri: PropTypes.string,
          }),
        })
      ),
      status: PropTypes.shape({
        'status-code': PropTypes.number,
        message: PropTypes.string,
      }).isRequired,
    }),
  }),
};

HeroZone.defaultProps = {
  heroContent: {},
};

export default HeroZone;
