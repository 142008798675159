import { useMemo, useRef } from 'react';
import { includes, isEmpty, lowerCase } from 'lodash';
import PropTypes from 'prop-types';
import {
  uriParameterGenerator,
  LATEST_DATA_LAYER,
} from 'domains/DataLayer/DataLayerURIGenerator';
import classNames from 'classnames';
import LazyLoadingImage from 'components/LazyLoadingImage/LazyLoadingImage';
import { accessibleRedirect, formatAuthors } from '../../../../utilities';
import { MEDIA_TYPES } from '../../../../constants';
import styles from './LatestCard.module.scss';

const LatestCard = ({ article, positionRank, isMobile }) => {
  const articleLinkRef = useRef(null);
  const formattedAuthors = useMemo(
    () => formatAuthors(article.authors),
    [article.authors]
  );

  const renderArticleTypeTag = () => {
    const { type } = article;

    if (type && includes(MEDIA_TYPES, lowerCase(type))) {
      return (
        <div className={styles.slug}>
          <span id={`latest-card-media-type-${positionRank}`}>
            {article.type}
          </span>
        </div>
      );
    }

    return null;
  };

  const imageToRender = !isEmpty(article.thumbnail?.uri)
    ? article.thumbnail
    : article.image;

  const redirectToArticle = (e) => {
    accessibleRedirect(e, () => articleLinkRef.current.click());
  };

  return (
    <article className={styles['latest-article']}>
      <a
        ref={articleLinkRef}
        href={uriParameterGenerator(
          article.uri,
          LATEST_DATA_LAYER.PARAMETER,
          positionRank
        )}
        className={classNames(styles.link, styles['card-link'])}
        aria-describedby={`latest-card-media-type-${positionRank} latest-card-authors-${positionRank}`}
        aria-label={`Read more about ${article.title}`}
        onKeyDown={redirectToArticle}
      >
        <div className={styles.text}>
          {renderArticleTypeTag()}
          <h2 className={styles.title}>{article.title}</h2>

          {!isEmpty(formattedAuthors) && !isMobile && (
            <span
              className={styles.author}
              id={`latest-card-authors-${positionRank}`}
              aria-label={`Authors: ${formattedAuthors}`}
            >
              {formattedAuthors}
            </span>
          )}
        </div>
        {isMobile && (
          <LazyLoadingImage
            alt={article.type}
            src={imageToRender?.uri}
            className={styles['article-image']}
            errorClassName={styles['fixed-image']}
            withWrapper={false}
          />
        )}
      </a>
    </article>
  );
};

LatestCard.defaultProps = {
  isMobile: false,
};

LatestCard.propTypes = {
  isMobile: PropTypes.bool,
  article: PropTypes.shape({
    authors: PropTypes.arrayOf(PropTypes.string).isRequired,
    image: PropTypes.shape({
      uri: PropTypes.string.isRequired,
    }),
    thumbnail: PropTypes.shape({
      uri: PropTypes.string.isRequired,
    }),
    topic: PropTypes.shape({
      name: PropTypes.string,
      uri: PropTypes.string,
    }),
    dek: PropTypes.string,
    title: PropTypes.string,
    type: PropTypes.string,
    uri: PropTypes.string,
  }).isRequired,
  positionRank: PropTypes.number.isRequired,
};

export default LatestCard;
