import { useMemo, useRef } from 'react';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import {
  uriParameterGenerator,
  FOR_YOU_DATA_LAYER,
} from 'domains/DataLayer/DataLayerURIGenerator';
import classNames from 'classnames';
import LazyLoadingImage from 'components/LazyLoadingImage/LazyLoadingImage';
import { useRouter } from 'next/navigation';
import {
  accessibleRedirect,
  formatAuthors,
  stripMarkupFromString,
} from '../../../../utilities';
import styles from './ForYouCard.module.scss';

const ForYouCard = ({ article, positionRank, isMobile }) => {
  const router = useRouter();
  const articleLinkRef = useRef(null);
  const formattedAuthors = useMemo(
    () => formatAuthors(article.authors),
    [article.authors]
  );

  const formattedDek = useMemo(
    () => stripMarkupFromString(article.dek),
    [article.dek]
  );
  const imageToRender = !isEmpty(article.thumbnail?.uri)
    ? article.thumbnail
    : article.image;

  const redirectToTopic = (e) => {
    accessibleRedirect(e, () =>
      router.push(
        uriParameterGenerator(
          article.topic.uri,
          FOR_YOU_DATA_LAYER.PARAMETER,
          positionRank
        )
      )
    );
  };

  const redirectToArticle = (e) => {
    accessibleRedirect(e, () => articleLinkRef.current.click());
  };

  return (
    <article className={styles['for-you-article']}>
      <a
        ref={articleLinkRef}
        aria-describedby={`for-you-dek-${positionRank} for-you-authors-${positionRank}`}
        aria-label={`Read more about ${article.title}`}
        href={uriParameterGenerator(
          article.uri,
          FOR_YOU_DATA_LAYER.PARAMETER,
          positionRank
        )}
        className={classNames(styles.link, styles['card-link'])}
        onKeyDown={redirectToArticle}
      >
        <LazyLoadingImage
          alt={article.type}
          src={imageToRender?.uri}
          className={styles['article-image']}
          errorClassName={styles['fixed-image']}
          lazyLoad={false}
          withWrapper={false}
        />

        <div className={styles.text}>
          {!isEmpty(article.topic) && !isMobile && (
            <span
              aria-label={`Read more about ${article.topic.name} topic`}
              className={styles.topic}
              tabIndex={0}
              role="link"
              onClick={redirectToTopic}
              onKeyDown={redirectToTopic}
            >
              {article.topic.name}
            </span>
          )}

          <h2 className={styles.title}>{article.title}</h2>

          {!isEmpty(article.dek) && !isMobile && (
            <p id={`for-you-dek-${positionRank}`} className={styles.dek}>
              {formattedDek}
            </p>
          )}

          {!isEmpty(formattedAuthors) && !isMobile && (
            <p
              className={styles.author}
              id={`for-you-authors-${positionRank}`}
              aria-label={`Authors: ${formattedAuthors}`}
            >
              {formattedAuthors}
            </p>
          )}
        </div>
      </a>
      {!isEmpty(article.topic) && !isMobile && (
        <a
          href={uriParameterGenerator(
            article.topic.uri,
            FOR_YOU_DATA_LAYER.PARAMETER,
            positionRank
          )}
          className="sr-only"
          aria-label={`Read more about ${article.topic.name} topic`}
          tabIndex={-1}
        >
          {article.topic.name}
        </a>
      )}
    </article>
  );
};

ForYouCard.defaultProps = {
  article: {},
  isMobile: false,
};

ForYouCard.propTypes = {
  isMobile: PropTypes.bool,
  article: PropTypes.shape({
    authors: PropTypes.arrayOf(PropTypes.string).isRequired,
    image: PropTypes.shape({
      uri: PropTypes.string.isRequired,
    }),
    thumbnail: PropTypes.shape({
      uri: PropTypes.string.isRequired,
    }),
    topic: PropTypes.shape({
      name: PropTypes.string.isRequired,
      uri: PropTypes.string.isRequired,
    }),
    dek: PropTypes.string,
    title: PropTypes.string,
    type: PropTypes.string,
    uri: PropTypes.string,
  }),
  positionRank: PropTypes.number.isRequired,
};

export default ForYouCard;
