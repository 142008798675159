import arrowsDesktop from '../../assets/images/SubscriberTout/arrows/arrows_desktop.png';
import arrowsMobile from '../../assets/images/SubscriberTout/arrows/arrows_mobile.png';
import pipesDesktop from '../../assets/images/SubscriberTout/pipes/pipes_desktop.gif';
import pipesMobile from '../../assets/images/SubscriberTout/pipes/pipes_mobile.gif';

const SUBSCRIBER_TOUT_SLIDES = [
  {
    header: 'Get the Best in Management Thinking',
    cta: 'Subscribe',
    cta_link:
      'https://hbr.org/subscriptions?ab=home-subtout&tpcc=houseads.site.home-subtout1',
    whiteContrast: true,
    desktopBackground: arrowsDesktop,
    mobileBackground: arrowsMobile,
  },
  {
    header: 'Get the Best in Management Thinking',
    cta: 'Subscribe',
    cta_link:
      'https://hbr.org/subscriptions?ab=home-subtout&tpcc=houseads.site.home-subtout2',
    whiteContrast: false,
    desktopBackground: pipesDesktop,
    mobileBackground: pipesMobile,
  },
];

export default SUBSCRIBER_TOUT_SLIDES;
