import classNames from 'classnames';
import styles from './SkipToMainContent.module.scss';

const SkipToMainContent = () => {
  return (
    <a className={classNames('sr-only', styles.link)} href="#main-content">
      Skip to content
    </a>
  );
};

export default SkipToMainContent;
