import { useMemo, useRef } from 'react';
import { isEmpty } from 'lodash';
import ComponentLoadFailure from 'components/ComponentLoadFailure/ComponentLoadFailure';
import PropTypes from 'prop-types';
import {
  uriParameterGenerator,
  FEATURED_DATA_LAYER,
} from 'domains/DataLayer/DataLayerURIGenerator';
import classNames from 'classnames';
import LazyLoadingImage from 'components/LazyLoadingImage/LazyLoadingImage';
import { useRouter } from 'next/navigation';
import useImageAspectRatio from '../../../hooks/useImageAspectRatio';
import {
  accessibleRedirect,
  formatAuthors,
  stripMarkupFromString,
} from '../../../utilities';
import styles from './Featured.module.scss';
import { STATUS_CODE_SUCCESS } from '../../../constants';

const featuredImageSizes =
  '(min-width: 1210px) 565px, (min-width: 1025px) 47vw, (min-width: 818px) 70vw, 100vw';

const Featured = ({ article, isMobile }) => {
  const router = useRouter();
  const articleLinkRef = useRef(null);
  const { windowHeight } = useImageAspectRatio();
  const formattedAuthors = useMemo(
    () => formatAuthors(article.authors),
    [article.authors]
  );

  const formattedDek = useMemo(
    () => stripMarkupFromString(article.dek),
    [article.dek]
  );

  const imageToRender = !isEmpty(article.thumbnail?.uri)
    ? article.thumbnail
    : article.image;

  const redirectToTopic = (e) => {
    accessibleRedirect(e, () =>
      router.push(
        uriParameterGenerator(
          article.topic.uri,
          FEATURED_DATA_LAYER.PARAMETER,
          1
        )
      )
    );
  };

  const redirectToArticle = (e) => {
    accessibleRedirect(e, () => articleLinkRef.current.click());
  };

  return (
    <article className={styles['featured-content']}>
      {article['response-status']['status-code'] !== STATUS_CODE_SUCCESS ? (
        <ComponentLoadFailure />
      ) : (
        <>
          <a
            ref={articleLinkRef}
            aria-describedby="featured-dek featured-authors"
            aria-label={`Read more about ${article.title}`}
            href={uriParameterGenerator(
              article.uri,
              FEATURED_DATA_LAYER.PARAMETER,
              1
            )}
            onKeyDown={redirectToArticle}
            className={classNames(styles.link, styles['card-link'])}
          >
            <div>
              <div
                className={styles['image-container']}
                style={{ height: `${windowHeight}px` }}
              >
                <LazyLoadingImage
                  alt={article.title}
                  src={imageToRender?.uri}
                  className={styles['featured-image']}
                  errorClassName={styles['fixed-image']}
                  lazyLoad={false}
                  srcset={imageToRender?.srcset}
                  sizes={featuredImageSizes}
                  cover
                  withWrapper={false}
                />
              </div>
              <div className={styles.article}>
                {!isEmpty(article.topic) && (
                  <span
                    aria-label={`Read more about ${article.topic.name} topic`}
                    className={styles.topic}
                    tabIndex={0}
                    role="link"
                    onClick={redirectToTopic}
                    onKeyDown={redirectToTopic}
                  >
                    {article.topic.name}
                  </span>
                )}

                <h1 className={styles.title}>{article.title}</h1>

                {formattedDek && (
                  <p id="featured-dek" className={styles.dek}>
                    {formattedDek}
                  </p>
                )}

                {!isEmpty(formattedAuthors) && !isMobile && (
                  <span
                    id="featured-authors"
                    aria-label={`Authors: ${formattedAuthors}`}
                    className={styles.authors}
                  >
                    {formattedAuthors}
                  </span>
                )}
              </div>
            </div>
          </a>
          {!isEmpty(article.topic) && (
            <a
              href={uriParameterGenerator(
                article.topic.uri,
                FEATURED_DATA_LAYER.PARAMETER,
                1
              )}
              className="sr-only"
              aria-label={`Read more about ${article.topic.name} topic`}
              tabIndex={-1}
            >
              {article.topic.name}
            </a>
          )}
        </>
      )}
    </article>
  );
};

Featured.imageSizes = featuredImageSizes;

Featured.defaultProps = {
  isMobile: false,
};

Featured.propTypes = {
  isMobile: PropTypes.bool,
  article: PropTypes.shape({
    'response-status': PropTypes.shape({
      'status-code': PropTypes.number,
      message: PropTypes.string,
    }).isRequired,
    type: PropTypes.string.isRequired,
    uri: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    dek: PropTypes.string,
    authors: PropTypes.arrayOf(PropTypes.string).isRequired,
    image: PropTypes.shape({
      srcset: PropTypes.string,
      uri: PropTypes.string.isRequired,
    }),
    thumbnail: PropTypes.shape({
      srcset: PropTypes.string,
      uri: PropTypes.string.isRequired,
    }),
    topic: PropTypes.shape({
      name: PropTypes.string,
      uri: PropTypes.string,
    }).isRequired,
  }).isRequired,
};

export default Featured;
